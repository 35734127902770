import Link from "next/link";
import { useEffect, useMemo, useRef, useState } from "react";
import styled from "styled-components";
import { getCookie, removeCookie, setCookie } from "../../common/auth";
import useClickOutside from "../../common/util/outsideClick";
import {
  openConfirmModal,
  modifyConfirmModal,
  closeConfirmModal,
} from "../../redux/action/confirm";
import { connect } from "react-redux";
import { changeNotiConfirmOption, logoutConfirm } from "../../common/confirm";
import { useRouter } from "next/router";
import { initBaskets } from "../../redux/action/baskets";
import { MobileSocialIconWrapper } from "./Footer.styles";
import HeaderCategory from "./Header-category";
import {
  CategoryWrapper,
  CouponInfo,
  MobilCategory,
  MobileCateBtn,
  MobileCategoryWrapper,
  MobileNotificationArea,
  NotificationArea,
} from "./Header.styles";
import MobileCategory from "./mobile-category";
import { ToggleButton } from "../../common/toggle";
import { postMember } from "../../api/members";
import { failAlert, successAlert } from "../../common/alert";
import { Sticker } from "../ecommerce/singleProduct/SingleProduct-styles";
import { priceComma } from "../../common/splicePrice";
import { PageRouter } from "../../common/pageRouter";

const UserInfoWrapper = styled.ul`
  display: flex;
  flex-direction: column;
  font-size: 20px;
  & h5 {
    margin-bottom: 1rem;
  }
  & div {
    display: flex;
  }
  & li {
    margin-right: 1rem;
  }
`;

const MobileMenu = ({
  isToggled,
  toggleClick,
  userInfo,
  show,
  openConfirmModal,
  modifyConfirmModal,
  closeConfirmModal,
  userCoupons,
}) => {
  const categoriRef = useRef(null);
  const router = useRouter();
  const [isActive, setIsActive] = useState({
    status: false,
    key: "",
  });
  const [btnText, setBtnText] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [isToggleActive, setIsToggleActive] = useState(false);
  const [notificationPermission, setNotificationPermission] = useState("");
  const [menus, setMenus] = useState(null);

  // 사용자 push 알림 허용 유무 정보
  const getPushStats = () => {
    if (userInfo?.Member?.pushYn === "Y") {
      setIsToggleActive(true);
    } else setIsToggleActive(false);
  };

  // 사용자 알림 on/off 설정 action handler
  const onClickToggleBtnActionHandler = async () => {
    setIsToggleActive(!isToggleActive);

    const body = {
      modes: "PushSet",
      select: !isToggleActive === true ? "Y" : "N",
    };

    const res = await postMember(body);

    if (res.Code === "0000") {
      closeConfirmModal();
      return successAlert(res.Data.Msg);
    } else {
      closeConfirmModal();
      return failAlert(res.Data.MSg);
    }
  };

  // 사용자 알림 on/off 설정
  const onClickToggleBtn = () => {
    modifyConfirmModal({
      ...changeNotiConfirmOption,
      onAction: () => {
        onClickToggleBtnActionHandler();
      },
    });
    return openConfirmModal();
  };

  const handleSearch = () => {
    if (searchTerm.length < 2) return failAlert("두 글자 이상 입력해주세요.");
    clearTimeout();
    router.push({
      pathname: "/search",
      query: {
        search: searchTerm,
      },
    });
    setSearchTerm("");
    toggleClick();
  };

  const userName = useMemo(() => {
    if (userInfo?.Member?.name) {
      return userInfo?.Member?.name + "님";
    } else {
      return null;
    }
  }, [userInfo]);

  const userCompanyName = useMemo(() => {
    if (userInfo?.Company?.Data?.code) {
      return userInfo?.Company?.Data?.name;
    } else {
      return "";
    }
  }, [userInfo]);

  // 토큰 유무에 따른 로그인 및 로그아웃 액션 처리
  const authHandler = () => {
    if (!getCookie("token")) {
      return router.push("/login");
    } else {
      logoutConfirmHandler();
    }
  };

  // 로그아웃 핸들러
  const logoutConfirmHandler = () => {
    removeCookie("token");

    if (getCookie("username")) removeCookie("username");

    if (router.asPath === "/") {
      initBaskets();
      closeConfirmModal();
      router.reload();
    } else {
      initBaskets();
      closeConfirmModal();
      router.push("/");
    }
  };

  // 토큰 유무에 따른 로그인 버튼 레이아웃 분기처리
  const showBtnText = () => {
    if (!getCookie("token")) {
      return setBtnText("로그인");
    } else if (getCookie("token")) {
      return setBtnText("로그아웃");
    }
  };

  const handleToggle = (key) => {
    if (isActive.key === key) {
      setIsActive({
        status: false,
      });
    } else {
      setIsActive({
        status: true,
        key,
      });
    }
  };

  let domNode = useClickOutside(() => {
    setIsActive({
      status: false,
    });
  });

  // 메뉴 라우팅
  const buildPage = (pages) => {
    if (!pages) return;
    const temp = [...pages];

    const menus = temp.map((page, pageIdx) => {
      if (!page.sub) {
        return (
          <li
            key={pageIdx}
            onClick={() => handleToggle(pageIdx)}
            className={
              isActive.key == pageIdx && page.sub
                ? "menu-item-has-children active"
                : "menu-item-has-children"
            }
          >
            <Link href={page.url}>
              <a>{page.title}</a>
            </Link>
          </li>
        );
      } else {
        return (
          <li
            key={pageIdx}
            onClick={() => handleToggle(pageIdx)}
            className={
              isActive.key == pageIdx
                ? "menu-item-has-children active"
                : "menu-item-has-children"
            }
          >
            <span className="menu-expand">
              <i className="fi-rs-angle-small-down"></i>
            </span>
            <a>{page.title}</a>
            <ul className={isActive.key == pageIdx ? "dropdown" : "d-none"}>
              {buildPage(page.sub)}
            </ul>
          </li>
        );
      }
    });
    return menus;
  };

  useEffect(() => {
    getPushStats();
    showBtnText();
  }, [userInfo]);

  useEffect(() => {
    setMenus(buildPage(PageRouter));
    if (typeof window !== "undefined" && "Notification" in window) {
      // 알림 허용 상태 변경 시 실행되는 함수
      const handlePermissionChange = () => {
        setNotificationPermission(Notification.permission);
      };

      // 알림 허용 상태 변경 이벤트를 감지하고 상태를 업데이트합니다.
      Notification.requestPermission().then(handlePermissionChange);
      window.addEventListener("change", handlePermissionChange);

      // 컴포넌트 언마운트 시 이벤트 리스너를 제거합니다.
      return () => {
        window.removeEventListener("change", handlePermissionChange);
      };
    }
  }, []);

  useEffect(() => {
    setMenus(buildPage(PageRouter));
  }, [isActive]);

  return (
    <>
      <div
        className={
          isToggled
            ? "mobile-header-active mobile-header-wrapper-style sidebar-visible "
            : "mobile-header-active mobile-header-wrapper-style"
        }
      >
        <div className="mobile-header-wrapper-inner">
          <div className="mobile-header-top">
            <div className="mobile-header-logo">
              <Link href="/">
                <a>
                  <img
                    src="/assets/imgs/theme/logo-header-icon-m.png"
                    alt="logo"
                    style={{ width: "200px" }}
                  />
                </a>
              </Link>
            </div>
            <div className="close-style-wrap close-style-position-inherit">
              <button
                className="close-style search-close"
                onClick={toggleClick}
              >
                <i className="icon-top"></i>
                <i className="icon-bottom"></i>
              </button>
            </div>
          </div>
          <div className="mobile-header-content-area">
            <div className="mobile-header-info-wrap ">
              <div className="single-mobile-header-info ">
                <UserInfoWrapper>
                  {userName ? (
                    <>
                      <div className="d-flex justify-content-between">
                        <h5 className="d-flex justify-content-start align-items-center">
                          {userName} {userCompanyName && `(${userCompanyName})`}
                        </h5>
                      </div>
                      <div className="mb-2">
                        <li>
                          <a className="d-flex justify-content-start align-items-center">
                            <Sticker className="mr-5">P</Sticker>
                            {priceComma(userInfo?.Member?.point || 0)} P
                          </a>
                        </li>
                        {Number(userCoupons?.total_count) > 0 && (
                          <CouponInfo>
                            <Link href="/mypage/coupon">
                              <a>
                                <i className="fi-rs-gift-card mr-10" />
                                쿠폰: {userCoupons?.total_count} 장
                              </a>
                            </Link>
                          </CouponInfo>
                        )}
                        {notificationPermission === "granted" && (
                          <MobileNotificationArea>
                            <i className="fi fi-rs-bell mr-10"></i>
                            <span>알림</span>
                            <ToggleButton
                              width="48px"
                              height="24px"
                              fontSize="10px"
                              bgColor="#b6b6b6"
                              btnWidth="24px"
                              btnBgColor="#3bb77e"
                              btnPadding="2px 4px"
                              btnLeft="22px"
                              disabledColor="#707070"
                              disabledLeft="2px"
                              mlAuto="auto"
                              isToggleActive={isToggleActive}
                              onClickToggleBtn={onClickToggleBtn}
                            />
                          </MobileNotificationArea>
                        )}
                      </div>
                      <div>
                        <li>
                          <Link href="/mypage/order">
                            <a style={{ fontSize: "14px" }}>
                              <i className="fi fi-rs-user mr-10"></i>마이페이지
                            </a>
                          </Link>
                        </li>
                        <li>
                          <Link href="/order-search">
                            <a style={{ fontSize: "14px" }}>
                              <i className="fi fi-rs-barcode mr-5"></i>
                              주문 조회
                            </a>
                          </Link>
                        </li>
                        <li>
                          <a
                            onClick={authHandler}
                            className="header-nav-logout-btn"
                            style={{ fontSize: "14px" }}
                          >
                            <i className="fi fi-rs-sign-out mr-10"></i>
                            {btnText}
                          </a>
                        </li>
                      </div>
                    </>
                  ) : (
                    <div>
                      <li>
                        <Link href="/login">
                          <a
                            style={{
                              fontSize: "14px",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <i className="fi fi-rs-lock-alt mr-5 pt-1" />
                            로그인
                          </a>
                        </Link>
                      </li>
                      <li>
                        <Link href="/register">
                          <a
                            style={{
                              fontSize: "14px",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <i className="fi fi-rs-user mr-5 pt-1" />
                            회원 가입
                          </a>
                        </Link>
                      </li>
                      <li>
                        <Link href="/order-search">
                          <a
                            style={{
                              fontSize: "14px",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <i className="fi fi-rs-barcode mr-5 pt-1" />
                            주문 조회
                          </a>
                        </Link>
                      </li>
                    </div>
                  )}
                </UserInfoWrapper>
              </div>
            </div>
            <div className="mobile-menu-wrap">
              <MobileCategoryWrapper ref={categoriRef}>
                <div
                  className={
                    true
                      ? "categori-dropdown-wrap d-flex"
                      : "categori-dropdown-active-small"
                  }
                >
                  <MobileCategory categoriRef={categoriRef} />
                </div>
              </MobileCategoryWrapper>
              <nav>
                <ul
                  className="mobile-menu"
                  ref={domNode}
                  style={{ cursor: "pointer" }}
                >
                  {menus}
                </ul>
              </nav>
            </div>
            <div className="mobile-social-icon mt-20">
              <MobileSocialIconWrapper>
                <Link href="https://pf.kakao.com/_MCxnhxj">
                  <a target="_blank">
                    <img src="/assets/imgs/theme/icons/kakao-talk.png" alt="" />
                  </a>
                </Link>
                <Link
                  href="https://www.facebook.com/caretstore"
                  target="_blank"
                >
                  <a target="_blank">
                    <img
                      src="/assets/imgs/theme/icons/icon-facebook-white.svg"
                      alt=""
                    />
                  </a>
                </Link>
                <Link
                  href="https://www.instagram.com/caretstore/"
                  target="_blank"
                >
                  <a target="_blank">
                    <img
                      src="/assets/imgs/theme/icons/icon-instagram-white.svg"
                      alt=""
                    />
                  </a>
                </Link>
                <Link href="https://talk.naver.com/ct/w4kznv" target="_blank">
                  <a target="_blank">
                    <img
                      src="/assets/imgs/theme/icons/icon-naver-talk.png"
                      alt=""
                    />
                  </a>
                </Link>
                <Link
                  href="https://www.youtube.com/channel/UC1MTYtvC363yWAB4uf8CBhQ"
                  target="_blank"
                >
                  <a target="_blank">
                    <img
                      src="/assets/imgs/theme/icons/icon-youtube-white.svg"
                      alt=""
                    />
                  </a>
                </Link>
                <Link href="https://blog.naver.com/caretstore" target="_blank">
                  <a target="_blank">
                    <img
                      src="https://cdn.imweb.me/upload/S20200506960caff1c89c9/522104c187709.png"
                      alt=""
                    />
                  </a>
                </Link>
              </MobileSocialIconWrapper>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  show: state.confirm.modal,
});

const mapDispatchToProps = {
  openConfirmModal,
  modifyConfirmModal,
  closeConfirmModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(MobileMenu);
