import React, { useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import Breadcrumb from "./Breadcrumb";
import Footer from "./Footer";
import Header from "./Header";
import MobileMenu from "./MobileMenu";
import Seo from "./Seo";
import { getAllUserInfo, getUserInfo } from "../../api/getUserInfo";
import { getCookie, removeCookie } from "../../common/auth";
import { useRouter } from "next/router";
import { showToastAlert } from "../../redux/action/alert";
import { failToSubmit } from "../../common/alert";
import styled from "styled-components";
import ChannelTalk from "../channel/ChannelTalk";
import { isLoading, isLoaded } from "../../redux/action/loading";
import Loading from "../elements/Loading";
import SideBanner from "./sideBanner";
import { setAllianceInfo } from "../../redux/action/alliance";
import { setBanners } from "../../redux/action/banners";
import { listSetBanners } from "../../api/banners";
import checkSiteConfig from "../../api/checkSiteConfig";
import { postOrder } from "../../api/order";
import { fetchUserInfo } from "../../redux/action/userInfo";

const Body = styled.main``;

const Layout = ({
  dummyList,
  children,
  parent,
  sub,
  subTitle,
  subChild,
  noBreadcrumb,
  headerStyle,
  showToastAlert,
  isLoading,
  isLoaded,
  allianceInfo,
  setAllianceInfo,
  setBanners,
  banners,
  fetchSiteInfo,
  siteConfig,
  pageDesc,
  userInfo,
  fetchUserInfo,
}) => {
  const router = useRouter();
  const [isToggled, setToggled] = useState(false);
  const [isSessionExpirated, setIsSessionExpirated] = useState(false);
  const [logo, setLogo] = useState({
    pc: "",
    mobile: "",
    over: "",
  });
  const toggleClick = () => {
    setToggled(!isToggled);
    isToggled
      ? document.querySelector("body").classList.remove("mobile-menu-active")
      : document.querySelector("body").classList.add("mobile-menu-active");
  };
  // 유저 정보 불러오는 함수 및 토큰 만료 시 분기 처리
  const getUser = async () => {
    await fetchUserInfo();
  };

  // 토큰 여부 파싱 후 유저 정보 불러오는 함수 트리거
  const isAccessTokenExisted = async () => {
    const accessToken = getCookie("token");

    if (accessToken) {
      getUser();
    } else {
      // gtag
      dataLayer.push({
        user_id: undefined,
      });
    }
  };

  const fetchBanners = async () => {
    const body = {
      modes: "ListSet",
    };
    const res = await listSetBanners(body);
    if (res?.Code === "0000") {
      setBanners(res?.Data?.rows);
    }
  };

  const checkLogo = async () => {
    if (router.query.channel) {
      if (
        sessionStorage.getItem("logo") &&
        JSON.parse(sessionStorage.getItem("logo")).pc.includes("alliance")
      ) {
        setLogo(JSON.parse(sessionStorage.getItem("logo")));
      } else {
        const body = {
          modes: "Config",
          division: "store",
          channel: router.query.channel,
        };
        const res = await checkSiteConfig(body);

        setLogo({
          pc: res.Data.channel.logo,
          mobile: res.Data.channel.mobile_logo,
          over: res.Data.channel.over_logo,
        });
      }
    } else {
      if (dummyList) {
        setLogo({
          pc: dummyList.logo?.logo,
          mobile: dummyList.logo?.mobile_logo,
          over: dummyList.logo?.over_logo,
        });
      }
    }
  };

  useEffect(() => {
    const channelTalk = new ChannelTalk();
    if (userInfo?.Member?.phone) {
      channelTalk.boot({
        pluginKey: process.env.NEXT_PUBLIC_CHANNELTALK_PLUGIN_KEY,
        memberId: userInfo?.Member.uid,
        profile: {
          name: userInfo?.Member.name,
          mobileNumber: userInfo?.Member?.phone,
          email: userInfo?.Member.email,
        },
      });
    } else {
      channelTalk.boot({
        pluginKey: process.env.NEXT_PUBLIC_CHANNELTALK_PLUGIN_KEY,
      });
    }
    return () => {
      channelTalk.shutdown();
    };
  }, [userInfo]);

  // 토큰 만료 시 toast 알림
  useEffect(() => {
    if (!router.isReady) return;
    checkLogo();
    fetchBanners();
    isAccessTokenExisted();

    const handleIsLoading = (e) => {
      isLoading();
    };
    const handleIsLoaded = (e) => {
      isLoaded();
    };

    router.events.on("routeChangeStart", handleIsLoading);

    router.events.on("routeChangeComplete", handleIsLoaded);

    return () => {
      router.events.off("routeChangeStart", handleIsLoading);

      router.events.off("routeChangeComplete", handleIsLoaded);
    };
  }, [router, router.query, dummyList]);

  const userCoupons = useMemo(() => {
    if (userInfo?.Coupon?.rows?.length > 0) {
      return userInfo?.Coupon;
    } else {
      return [];
    }
  }, [userInfo]);

  return (
    <>
      <Seo title={subChild} desc={pageDesc} />

      {isToggled && (
        <div className="body-overlay-1" onClick={toggleClick}></div>
      )}

      <Header
        headerStyle={headerStyle}
        isToggled={isToggled}
        toggleClick={toggleClick}
        userInfo={userInfo}
        logo={logo}
        userCoupons={userCoupons}
        siteConfig={siteConfig}
      />

      <MobileMenu
        isToggled={isToggled}
        toggleClick={toggleClick}
        userInfo={userInfo}
        userCoupons={userCoupons}
      />

      <Body className="main position-relative">
        <Breadcrumb
          parent={parent}
          sub={sub}
          subTitle={subTitle}
          subChild={subChild}
          noBreadcrumb={noBreadcrumb}
        />
        {children}
      </Body>
      <Footer />
    </>
  );
};

const mapStateToProps = (state) => ({
  allianceInfo: state.allianceInfo,
  banners: state.banners,
  dummyList: state.dummyList,
  userInfo: state.userInfo,
});

const mapDispatchToProps = {
  showToastAlert,
  isLoading,
  isLoaded,
  setAllianceInfo,
  setBanners,
  fetchUserInfo,
};

export default connect(mapStateToProps, mapDispatchToProps)(Layout);
